#no-orders {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#no-order-icon {
  width: auto;
  height: 10rem;
}
#no-orders-title {
  font-style: normal;
  font-size: 2rem;
  color: #00a3ff;
  text-align: center;
}
