
.content {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  background-color: rgb(184, 235, 255);
}

h1{
  text-align: center;
}
.Notafallkontakte{
  height: 20%;
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
}
.service-contact{

  padding: 20px 40px;
  height: 80%;
  margin: 0px;

}
.service-contact p{
  font-size: 21px;
}
.bootm-info{

  align-self: center;
  text-align: center;
}
.mr-10 {
  margin-right: 10px;
}
.delivery-partner {
  max-width: 150px;
}
.delivery-provider {
  width: 80%;
  margin: 80px 10% 0 10%;
  padding-bottom: 20px;
  border-bottom: 1px dashed;
}
.flex {
  display: flex;
  flex-direction: row;
}
