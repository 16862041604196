.no-messanged-orders {
  display: flex;
  margin: 120px 0px;
  justify-content: center;
}
.no-messanged-orders .no-messanged-orders-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.no-messanged-orders .no-messanged-orders-title article {
  color: #7a799d;
}
.no-messanged-step-wrapper {
  width: 608px;
  display: flex;
}
.no-messanged-step {
  width: 25%;
  padding: 16px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.no-messanged-step .horizontal-line-step {
  width: 100%;
  top: 33px;
  left: 0;
  position: absolute;
  height: 2px;
  background: #e5e5e5;
}

.no-messanged-step .number-step {
  width: 36px;
  display: flex;
  font-size: 16px;
  background-color: #fff;
  z-index: 2;
  height: 36px;
  color: #7a799d;
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  margin: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.no-messanged-step .text-step {
  padding: 8px 5px;
  font-size: 16px;
}
.botendienst-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 23px;
  border-bottom: 1px solid #e5e5e5;
}

.status-badge {
  display: flex;
  justify-content: center;
  padding: 2px 10px;
  border-radius: 24px;
  font-weight: 500;
  white-space: nowrap;
}
.status-badge.pending {
  background-color: #6366f1;
  color: #fff;
}
.status-badge.delivered {
  background-color: #22c55e;
  color: #fff;
}
.status-badge.in-transit {
  background-color: #f59e0b;
  color: #fff;
}
.status-badge.closed {
  background-color: #ef4444;
  color: #fff;
}
.status-badge.delivery_failed {
  background-color: #ef4444;
  color: #fff;
}
th.ant-table-cell {
  text-transform: uppercase;
  color: #5d5c77 !important;
}
