#navbar {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  margin-bottom: 1rem;
  position: fixed;
}
.back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1rem;
  left: 0;
}
.back-button:hover {
  cursor: pointer;
}
.nav-title {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 0.8rem !important;
  font-size: 1rem;
  font-family: 'BRSonoma-Regular', sans-serif;
  font-weight: 700;
}
.fa-chevron-left {
  color: #000000;
  font-size: 2rem;
}
.order-title {
  font-size: 2rem;
  color: rgba(255, 0, 0, 1);
  align-self: center;
  align-items: center;
  margin: 0;
  font-family: 'BRSonoma-Regular', sans-serif;
  font-weight: 700;
}
.order-title span {
  color: #000;
}