.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #c23031;
}

.ant-form-item-control-input ul {
  list-style-type: none;
  padding-left: 0;
  border-left: 1px solid #a9a9a9;
  border-top: 1px solid #a9a9a9;
  border-radius: 5px;
  border-right: 1px solid #a9a9a9;
}
.ant-form-item-control-input li {
  border-bottom: 1px solid #a9a9a9;
  padding: 2px 4px;
  border-radius: 5px;
}
.ant-form-item-control-input li:hover {
  cursor: pointer;
}

.table-header-reset-filter {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0px;
  gap: 16px;
}
.secondary-button {
  background: #eef2ff;
  color: #4b61df;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: auto;
  border: 0;
  gap: 8px;
  font-size: 16px;
}

.secondary-button:disabled {
  color: #a5b4fc;
  background-color: #eef2ff;
}
.secondary-button:disabled svg path{
  fill: #a5b4fc;
}

.ant-table-column-title{
  text-transform: uppercase;
  color: #5D5C77;
}
.ant-pagination{
  justify-content: center!important;
}