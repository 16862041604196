@import './css/flexboxgrid.css';
@import './css/dashboard.css';
@import './css/side-bar.css';
@import './css/orders.css';
@import './css/navbar.css';
@import './css/address.css';
@import './css/rider.css';

@font-face {
  font-family: 'BRSonoma-Regular';
  src: url('/public/assets/fonts/BRSonoma-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'BRSonoma-Medium';
  src: url('/public/assets/fonts/BRSonoma-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'BRSonoma-SemiBold';
  src: url('/public/assets/fonts/BRSonoma-SemiBold.otf') format('opentype');
}
body {
  padding: 0;
  margin: 0;
  font-family: 'BRSonoma-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'BRSonoma-Regular', sans-serif;
  line-height: normal;
  background: #f8f9fe;
}
body,
.ant {
  font-family: 'BRSonoma-Regular', sans-serif !important;
}

.ant-table-content {
  overflow: auto hidden;
}
.ant-typography {
  font-family: 'BRSonoma-Regular', sans-serif;
}
.ant-drawer-title {
  font-family: 'BRSonoma-Regular', sans-serif;
  font-size: 24px !important;
}
.ant-drawer-body .ant-form-item label {
  font-weight: 500;
}
.ant-form-item-label {
  padding-bottom: 4px !important;
}
.ant-form-item-control-input-content .ant-picker {
  width: 100%;
}
.submit-order-button {
  font-weight: 500;
  font-family: 'BRSonoma-Medium', sans-serif !important;
}
.submit-order-button:disabled {
  background-color: #a5b4fc !important;
  color: #fff;
}
code,
textarea,
input,
a,
span {
  font-family: 'BRSonoma-Regular', sans-serif !important;
}

.login-page {
  width: 360px;
  padding: 16% 0 0;
  margin: auto;
}

.login-form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.login-form input:hover {
  background: #f7f7f7;
}

.login-form button {
  outline: 0;
  background: #4b517f;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.login-form button:active {
  background: #1f223a;
}

.login-form button:hover {
  background: #717a9e;
}

.error {
  color: red;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
