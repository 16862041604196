.address-body {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 20px 10% 1% 10%;
}
.address-container {
  margin: 0px 1rem;
}
.label-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.address-container > p {
  font-size: 1rem;
  font-weight: 700;
  font-family: 'BRSonoma-Regular', sans-serif;
}
.address {
  font-family: 'BRSonoma-Regular', sans-serif;
  font-weight: 400;
  font-size: 0.7rem;
  margin-bottom: 1.5rem;
}
.header-title {
  font-family: 'BRSonoma-Regular', sans-serif;
  font-weight: 700;
}
.details-title {
  display: 'flex';
  width: '100%';
  height: auto;
  display: flex;
  justify-content: center;
  align-items: 'center';
  text-align: 'center';
  margin-top: 2rem;
}
.details-title > p {
  font-size: 1.5rem;
  font-family: 'BRSonoma-Regular', sans-serif;
  font-weight: 700;
}
.contact-details {
  margin-top: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.contact-details p {
  font-family: 'BRSonoma-Regular', sans-serif;
  font-size: 2.5;
  font-weight: 700;
}
