.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  width: 20vw;
  max-width: 240px;
  min-width: 180px;

  border-right: 1px solid #e7e8ec;
  background-color: #fff;
  font-family: 'BRSonoma-Regular', sans-serif;
  font-weight: 700;
  font-style: normal;
}
.logo {
  display: flex;
  height: 10%;
  justify-content: flex-start;
  align-items: center;
}
.logo > img {
  margin-top: 1rem;
  width: 100%;
  max-width: 150px;
  height: auto;
  object-fit: scale-down;
  padding: 20px;
  box-sizing: border-box;
}

.pages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 90%;
  justify-content: flex-start;
}

.pages hr {
  /* height: 100%;
  font-weight: bold;
  font-style: normal; */
  width: 80%;
  margin: 0 !important;
  margin-top: 0.5rem;
}

.sidebar .link {
  font-family: 'BRSonoma-Regular', sans-serif;
  border-left: 5px solid transparent;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  color: rgba(0, 0, 0, 0.46);
  text-decoration: none;
  padding: 16px;
  overflow: scroll;
}
.sidebar .link:hover{
  background-color: #FAFAFA;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pages .link::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar .link svg {
  margin-right: 12px;
}
.sidebar .link.active {
  color: #4b61df !important;
  text-decoration: none;
  box-sizing: border-box;
  border-left: 5px solid var(--colors-primary-400, #5b71ef);
  background: rgba(91, 113, 239, 0.2);
}
.sidebar .link.active span {
  color: #4b61df;
}

.sidebar .link.active::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.emergency .active {
  text-decoration: none;
  overflow: scroll;
}