.rider-body {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0px 10%;
  margin-bottom: 1%;
}
.rider-container {
  margin: 0px 1rem;
}
.rider-container > p {
  font-size: 1rem;
  font-weight: 700;
  font-family: 'BRSonoma-Regular', sans-serif;
}
