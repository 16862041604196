.orders-header {
  display: flex;
  border-bottom: 1px solid #000000;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}

.orders-header > th {
  font-style: normal;
  font-weight: bold;
  color: #000000;
  font-family: 'BRSonoma-Regular', sans-serif;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}
.item {
  margin-top: 0.8%;
  margin-bottom: 1.5%;
  background: rgba(0, 163, 255, 0.11);
  border-radius: 8px;
  padding: 0.5rem;
  width: 80vw;
}
.item-details {
  display: flex;
  justify-content: space-between;
  width:100%
}

.item-details > td > div > p {
  color: #0500ff;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  font-weight: 700;
}

.item-details > td {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.item-action {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.item-action button {
  color: #ffffff;
  background: #ff0000;
  border-radius: 5px;
  border: none;
  width: 30%;
}

.item-action-history {
  display: flex;
  justify-content: start;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 35px;
}

.task-completed-button {
  font-weight: 700;
  color: #ffffff;
  background: limegreen;
  border-radius: 5px;
  border: none;
  padding: 0.5rem;
}

.task-cancelled-button {
  font-weight: 700;
  color: #ffffff;
  background: red;
  border-radius: 5px;
  border: none;
  padding: 0.5rem;
}

.details-button {
  font-weight: 700;
  color: #000000;
  background: rgba(0, 163, 255, 0.26);
  border-radius: 5px;
  border: none;
  padding: 0.5rem;
  width: 100%;
}

.orders-table {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: fixed;
  height: 87%;
}

.orders-table th {
  border-bottom: 1px solid black;
  padding-left: 20px;
  text-align: center;
}

.container {
  display: flex;
  margin: 0px;
  border: 1px solid black;
}

.heading {
  text-align: center;
  font-weight: 700;
}

.order-el {
  background-color: rgb(227, 245, 255);
  padding: 20px;
  height: 50px;
}

.details {
  background-color: rgb(166, 226, 254);
  border-radius: 3px;
  padding: 10px 20px;
  border: 0px;
  font-weight: bold;
}

.placed {
  border-radius: 3px;
  color: white;
  padding: 10px 20px;
  border: 0px;
  font-weight: bold;
  width: 70%;
}

tr.order-el th {
  color: blue;
  border-bottom: 1em solid rgb(227, 245, 255);
  border-top: 1em solid rgb(227, 245, 255);
}
.content {
  display: flex;
  width: 70%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content img{
  padding-top: 30%;
  padding-bottom: 20px;
  opacity:0.5 ;
}
.maintext{
   color:rgb(3, 164, 255) ;
   text-align: center;
}
.submit_btn {
  border-radius: 3px;
  color: white;
  padding: 5px 10px;
  border: 0;
  width: 80%;
  height: 3rem;
  background-color: #00a3ff;
  font-weight: 700;
  font-size: 1.5rem;
}

.disabled_btn {
  background-color: rgba(0, 0, 0, 0.25);
}
textarea {
  padding: 10px;
  font-weight: 300;
}
.comment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.comment-input-box {
  width: 80%;
  height: 80px;
  box-sizing: border-box;
  border: 0.7px solid rgba(0, 163, 255, 1);
  font-size: 1rem;
  font-weight: 700;
  padding: 0.8rem;
  color: rgba(0, 0, 0, 0.25);
}
.comment-input-box:focus {
  border-color: rgba(0, 163, 255, 1);
  color: #000;
}
.confirmation-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.confirmation-container {
  width: 80%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.form-control {
  font-weight: 700;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.confirmation-checkbox {
  margin-right: 0.5rem;
}
.form-control > input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: black;
  width: 1.5em;
  height: 1.5em;
  border: 0.15em solid rgba(255, 0, 0, 1);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type='checkbox']:disabled {
  border-color: #959495;
  cursor: not-allowed;
}

.form-control > input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.7em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em black;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.form-control > input[type='checkbox']:checked::before {
  transform: scale(1.9);
}

.form-control + .form-control {
  margin-top: 1em;
}

/* Warning */
.warning-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.68);
  display: flex;
  justify-content: center;
  align-items: center;
}
.warning-body {
  max-width: 30%;
  height: 35%;
  background-color: rgba(0, 163, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 3px;
  z-index: 1;
}
.warning-title {
  font-size: 1.1rem !important;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
.warning-button {
  padding: 1rem 3rem;
  color: #000;
  background-color: #fff;
  font-size: 1.5;
  font-weight: 700;
  border: 0;
  border-radius: 3px;
}

.table {
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  border-color: rgba(0, 0, 0, 1);
  border-top-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.table-row {
  display: flex;
  width: 100%;
  /* height: 100px; */
  justify-content: space-around;
}

.table-item {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
  border-bottom-width: 0;
}
.table-item p {
  font-size: 1rem;
  font-weight: 700;
}
.table-heading {
  height: 2rem;
}

.table-in {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}

.common_btn {
  border-radius: 3px;
  color: #fff;
  padding: 5px 10px;
  border: 0px;
  font-weight: bold;
  width: 7rem;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.46);
}

.common_btn_green {
  border-radius: 3px;
  color: #000;
  padding: 5px 10px;
  border: 0px;
  font-weight: bold;
  width: 7rem;
  height: 3rem;
  background-color: rgba(18, 230, 0, 0.46);
}
.custom-btn-body {
  position: relative;
  z-index: 1;
}
.status {
  border-radius: 3px;
  color: #fff;
  padding: 5px 10px;
  border: 0px;
  font-weight: bold;
  width: 9rem;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.46);
}
.item-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  top: auto;
  left: 0;
  width: 100%; */
}
.btn-item {
  /* z-index: 100; */
  border-radius: 3px;
  color: #000;
  padding: 5px 10px;
  border: 0px;
  font-weight: bold;
  width: 9rem;
  height: 2rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.heading {
  margin: 0 10px;
  text-align: center;
  margin-top: 2%;
}

.order-el {
  background-color: rgb(227, 245, 255);
  padding: 20px;
  height: 50px;
}

.details {
  background-color: rgb(166, 226, 254);
  border-radius: 3px;
  padding: 10px 20px;
  border: 0px;
  font-weight: bold;
}

.placed {
  background-color: rgb(170, 185, 192);
  border-radius: 3px;
  color: white;
  padding: 10px 20px;
  border: 0px;
  font-weight: bold;
  width: 70%;
}

tr.order-el th {
  color: blue;
  border-bottom: 1em solid rgb(227, 245, 255);
  border-top: 1em solid rgb(227, 245, 255);
}

.container {
  display: flex;
  margin: 0px;
  border: 1px solid black;
}

.heading {
  margin: 0 10px;
  text-align: center;
  margin-top: 2%;
}

.order-el {
  background-color: rgb(227, 245, 255);
  padding: 20px;
  height: 50px;
}

.details {
  background-color: rgb(166, 226, 254);
  border-radius: 3px;
  padding: 10px 20px;
  border: 0px;
  font-weight: bold;
}

.placed {
  border-radius: 3px;
  color: white;
  padding: 10px 20px;
  border: 0px;
  font-weight: bold;
  width: 70%;
}

tr.order-el th {
  color: blue;
  border-bottom: 1em solid rgb(227, 245, 255);
  border-top: 1em solid rgb(227, 245, 255);
}

.maintext {
  color: red;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
}
.success_btn {
  padding: 1rem 2rem;
  border: white;
  color: white;
  border-radius: 3px;
  border: 0px;
  font-weight: 700;
  font-size: 1.5rem;
  width: 30%;
  background-color: #00a3ff;
  margin-top: 1rem;
}

table tr {
  border: 0
 }

table tr:nth-child(2n) {
  border-bottom: 1px solid #000;
}

.ant-picker-content tr{
  border-bottom: none!important;
}

.btn-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-bottom: 1%;
    margin-left: 10%;
}

.btn-container .btn-item {
  width: auto;
  background-color: #00a3ff;
  color: white;
}

table tr {
  border: 0
 }

table tr:nth-child(2n) {
  border-bottom: 1px solid #000;
  color: white
}
.ant-table-row:nth-child(2n) {
  color:rgba(0, 0, 0, 0.88)!important;
}

/* Substitute */
.substitute-input-container {
  padding-left: 10;
  padding-right: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.substitute-input {
  min-width: 55%;
  height: 2.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  box-sizing: border-box;
}

.substitute-input-default-border {
  border: 2px solid rgb(232, 232, 232);
}

.substitute-input-error-border {
  border: 2px solid red;
}

.no-border {
  border-top-width: 0;
  border-bottom: 1px solid #000;
}

.light-gray {
  background-color: rgba(196, 196, 196, 0.12);
}

.substitute-input:focus {
  outline: none;
  border: 2px solid #000;
}

.substitute-input:disabled {
  color: rgb(154, 153, 153);
}